.arabic-container {
  position: relative;
  background-color: #f5f5f5;
}

.arabic-title {
  top: 200px;
  left: 40px;
  position: absolute;
  text-align: right;
  background-color: rgb(255, 161, 169, 0.9);
  padding: 30px;
  border-radius: 10px;
}

.arabic-title-big {
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 5px;
}

.arabic-text-area {
  font-size: 1.7em;
  line-height: 1.4;
  padding: 5% 20% 0 20%;
}

.arabic-schedule-link {
  color: #1f98f4;
  text-decoration: none;
  font-weight: 700;
  display: inline-block;
}

.arabic-video {
  text-align: center;
  width: 100%;
  height: 1000px;
  padding: 10%;
}

.arabic-imam-speech-container {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 100px auto 50px;
  padding: 20px;
  position: relative;
  width: 60%;
  box-shadow: 0 0 35px -16px rgb(0 0 0 / 75%);
  border-radius: 10px;
}

.arabic-imam-image {
  margin: 0 30px 0 0;
  display: block;
  width: 100px;
  height: 100px;
}

.arabic-imam-quote {
  margin-bottom: 20px;
  font-size: 20px;
  word-spacing: 2px;
}

.arabic-application-container {
  background-color: #f8f8ff;
}

.arabic-application-article {
  text-align: center;
  padding: 70px;
  font-size: 2.1em;
}

.arabic-application-text {
  text-align: center;
  padding: 50px;
  font-size: 1.1em;
}

//chat

.arabic-input {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 280px;
  border-radius: 3px;
  overflow: hidden;

  .arabic-label {
    position: absolute;
    top: -5px;
    left: 12px;
    font-size: 16px;
    color: rgba(0, 0, 0, .5);
    font-weight: 500;
    transform-origin: 0 0;
    transform: translate3d(0, 0, 0);
    transition: all .2s ease;
    pointer-events: none;
  }

  .arabic-description-label {
    top: -147px;
  }

  .arabic-focus-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: left;
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    border: 0;
    font-family: inherit;
    padding: 16px 12px 0 12px;
    height: 56px;
    font-size: 16px;
    font-weight: 400;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .3);
    color: #000;
    transition: all .15s ease;

    &:hover {
      background: rgba(0, 0, 0, .04);
      box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .5);
    }

    &:not(:-ms-input-placeholder) {
      + {
        .arabic-label {
          color: rgba(0, 0, 0, .5);
          transform: translate3d(0, -12px, 0) scale(.75);
        }
      }
    }

    &:not(:placeholder-shown) {
      + {
        .arabic-label {
          color: rgba(0, 0, 0, .5);
          transform: translate3d(0, -12px, 0) scale(.75);
        }
      }
    }

    &:focus {
      background: rgba(0, 0, 0, .05);
      outline: 0;
      box-shadow: inset 0 -2px 0 #07f;

      + {
        .arabic-label {
          color: #07f;
          transform: translate3d(0, -12px, 0) scale(.75);

          + {
            .arabic-focus-bg {
              transform: scaleX(1);
              transition: all 10ms ease;
            }
          }
        }
      }
    }
  }

  textarea {
    resize: none;
    border: none;
    outline: 0;
    height: 180px;
  }
}

.arabic-textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  border: 0;
  font-family: inherit;
  padding: 25px 12px 0 12px;
  height: 56px;
  font-size: 16px;
  font-weight: 400;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .3);
  color: #000;
  transition: all .15s ease;
  max-width: 400px;
  background-color: #fff;
  letter-spacing: 1px;
  margin: 0;

  &:hover {
    background: rgba(0, 0, 0, .04);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .5);
  }

  &:not(:-ms-input-placeholder) {
    + {
      .arabic-label {
        color: rgba(0, 0, 0, .5);
        transform: translate3d(0, -12px, 0) scale(.75);
      }
    }
  }

  &:not(:placeholder-shown) {
    + {
      .arabic-label {
        color: rgba(0, 0, 0, .5);
        transform: translate3d(0, -12px, 0) scale(.75);
      }
    }
  }

  &:focus {
    background: rgba(0, 0, 0, .05);
    outline: 0;
    box-shadow: inset 0 -2px 0 #07f;

    + {
      .arabic-label {
        color: #07f;
        transform: translate3d(0, -12px, 0) scale(.75);

        + {
          .arabic-focus-bg {
            transform: scaleX(1);
            transition: all 10ms ease;
          }
        }
      }
    }
  }
}

.arabic-response-alarm {
  padding: 20px;
  color: white;
  opacity: 1;
  transition: opacity 0.6s;
  margin-bottom: 15px;
}

.arabic-success {
  background-color: rgba(80, 192, 65, 0.99);
}

.arabic-error {
  background-color: #db7093;
}

.arabic-alarm {
  font-size: 12px;
  color: #db7093;
  margin-bottom: 10px;
}

.arabic-form-horizontal {
  max-width: 400px;
  font-weight: 400;
  margin: 0 auto;
  padding-bottom: 50px;
  padding-top: 50px;

}

.arabic-form-control {
  max-width: 400px;
  background-color: #fff;
  color: #000;
  letter-spacing: 1px;
  margin: 0;
}

.arabic-send-button {
  margin-top: 15px;
  height: 34px;
  width: 400px;
  overflow: hidden;
  transition: all .2s ease-in-out;
}

.arabic-alt-send-button {
  width: 400px;
  height: 34px;
  transition: all .2s ease-in-out;
  margin-top: 10px;

  &:hover {
    transform: translate3d(0, -29px, 0);
  }
}

.arabic-send-text {
  display: block;
  margin-top: 13px;
  font: 700 12px Lato, sans-serif;
  letter-spacing: 2px;
}

.arabic-list {
  list-style-type: none;
  margin-left: 20px;
  padding-right: 20px;
}

.arabic-learn-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  width: 25em;
  height: 3em;
  padding: 0 4em 0 1em;
  background: url(caret-down-solid.svg) no-repeat right 0.8em center/0.8em, #fff;
  color: gray;
  border-radius: 0.25em;
  cursor: pointer;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 30%);

  option {
    color: inherit;
    background-color: rgba(0, 0, 0, .3);
  }

  &:focus {
    outline: none;
  }

  &::-ms-expand {
    display: none;
  }

  &:hover {
    background: url(caret-down-solid.svg) no-repeat right 0.8em center/0.8em, rgba(0, 0, 0, .04);
  }
}


@media screen and (max-width: 1300px) {
  .arabic-title-big {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 2px;
  }
  .arabic-text-area {
    font-size: 1.5em;
    padding: 5% 30%;
  }
  .arabic-video {
    height: 700px;
  }
}

@media screen and (max-width: 900px) {
  .arabic-form-horizontal {
    padding: 0 0 100px;
  }
  .arabic-alt-send-button {
    margin-top: 3px;
  }
  .arabic-list {
    padding-right: 0;
  }

  .arabic-title {
    top: 150px;
    left: 5%;
    position: center;
    text-align: right;
    padding: 15px;
    border-radius: 5px;
  }
}

@media screen and (max-width: 569px) {
  .arabic-form-control {
    margin: 0 auto;
  }
  .arabic-video {
    height: 350px;
  }
  .arabic-imam-speech-container {
    margin-top: 50px
  }
  .arabic-text-area {
    font-size: 20px;
    padding: 10% 9%;
  }

  .arabic-imam-quote {
    font-size: 18px;
    word-spacing: 2px;
  }
  .arabic-imam-image {
    margin: 0 15px 0 0;
    display: block;
    width: 60px;
    height: 60px;
  }
  .arabic-title {
    top: 4%;
    left: 0;
    position: center;
    text-align: right;
    padding: 15px;
    border-radius: 5px;
  }
  .arabic-imam-speech-container {
    width: 80%;
  }
  .arabic-title {
    left: 0;
  }
}

@media screen and (max-width: 420px) {
  .arabic-send-button {
    width: 99%;
  }
}
