.payment-security-guarantees-container {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 100px auto 50px;
  padding: 20px;
  position: relative;
  width: 80%;
  box-shadow: 0 0 35px -16px #000;
  border-radius: 10px;
}

.payment-security-guarantees-content {
  margin-bottom: 20px;
  font-size: 20px;
  word-spacing: 2px;
  line-height: 31px;
}

.payment-security-guarantees-link {
  color: #1f98f4;
  text-decoration: none;
  font-weight: 700;
}
@media screen and (max-width: 569px) {

  .payment-security-guarantees-container {
    margin-top: 50px;
    width: 98%;
  }
  .payment-security-guarantees-content {
    font-size: 18px;
    word-spacing: 2px;

  }
}
