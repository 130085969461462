
.profile {
    background-color: #01bf71;
    /*position: absolute;*/
    /*margin-top: 10px;*/
    padding: 30px 20px;
    font-size: 20px;
    line-height: 1.6em;
    font-weight: bold;
}

.clr {
    clear: both;
}

.create-news-subject {
    background: navajowhite;
    color: black;
    padding: 10px 15px;
    margin-bottom: 10px;
    border: none;
    border-radius: 25px;
    font-size: 18px;
    display: block;
}

.create-news-text {
    background: navajowhite;
    color: black;
    padding: 10px 15px;
    border: none;
    border-radius: 25px;
    width: 70%;
    font-size: 18px;
    min-height: 200px;
}

.actions {
    background: seagreen;
    padding: 30px 30px;
}

.admin-checkbox {
    /*display: block;*/
    height: 25px;
    width: 25px;
    background-color: #252e48;
    color: palegreen;
    padding: 0 10px;
    margin: 10px;
}

.admin-checkbox-text {
    padding: 3px 5px;
    letter-spacing: 2px;
}

.actions p {
    padding: 20px;
    font-size: 20px;

    color: #252e48;
    letter-spacing: 2px;
}






