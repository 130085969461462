.volunteer-container {
  background-color: #fff;
  padding-top: 10%;
}

.volunteer-heading {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3em;
  display: inline;
  background-color: rgb(255, 255, 255, 0.7);
  transition: color 1s cubic-bezier(.25, .46, .45, .94);

  &:hover {
    background-color: rgb(255, 255, 255, 0.85);
  }
}

.volunteer-title {
  text-align: center;
  font-size: 2em;
  font-weight: bold;
}

.volunteer-video {
  text-align: center;
  width: 100%;
  height: 1000px;
  padding: 10%;
}

@media screen and (max-width: 1300px) {
  .volunteer-video {
    height: 700px;
  }
  .volunteer-heading {
    top: 6%;
    font-size: 2.5em;
  }

}

@media screen and (max-width: 900px) {
  .volunteer-heading {
    top: 8%;
    font-size: 2em;
  }
}

@media screen and (max-width: 569px) {
  .volunteer-video {
    height: 350px;
  }
  .volunteer-heading {
    top: 7%;
    font-size: 1.5em;
  }
}


// chat


.volunteer-input {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 280px;
  border-radius: 3px;
  overflow: hidden;

  .volunteer-label {
    position: absolute;
    top: -5px;
    left: 12px;
    font-size: 16px;
    color: rgba(0, 0, 0, .5);
    font-weight: 500;
    transform-origin: 0 0;
    transform: translate3d(0, 0, 0);
    transition: all .2s ease;
    pointer-events: none;
  }

  .volunteer-description-label {
    top: -147px;
  }

  .volunteer-focus-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: left;
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    border: 0;
    font-family: inherit;
    padding: 16px 12px 0 12px;
    height: 56px;
    font-size: 16px;
    font-weight: 400;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .3);
    color: #000;
    transition: all .15s ease;

    &:hover {
      background: rgba(0, 0, 0, .04);
      box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .5);
    }

    &:not(:-ms-input-placeholder) {
      + {
        .volunteer-label {
          color: rgba(0, 0, 0, .5);
          transform: translate3d(0, -12px, 0) scale(.75);
        }
      }
    }

    &:not(:placeholder-shown) {
      + {
        .volunteer-label {
          color: rgba(0, 0, 0, .5);
          transform: translate3d(0, -12px, 0) scale(.75);
        }
      }
    }

    &:focus {
      background: rgba(0, 0, 0, .05);
      outline: 0;
      box-shadow: inset 0 -2px 0 #07f;

      + {
        .volunteer-label {
          color: #07f;
          transform: translate3d(0, -12px, 0) scale(.75);

          + {
            .volunteer-focus-bg {
              transform: scaleX(1);
              transition: all 10ms ease;
            }
          }
        }
      }
    }
  }

  textarea {
    resize: none;
    border: none;
    outline: 0;
    height: 180px;
  }
}

.volunteer-textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  border: 0;
  font-family: inherit;
  padding: 25px 12px 0 12px;
  height: 56px;
  font-size: 16px;
  font-weight: 400;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .3);
  color: #000;
  transition: all .15s ease;
  max-width: 400px;
  background-color: #fff;
  letter-spacing: 1px;
  margin: 0;

  &:hover {
    background: rgba(0, 0, 0, .04);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .5);
  }

  &:not(:-ms-input-placeholder) {
    + {
      .volunteer-label {
        color: rgba(0, 0, 0, .5);
        transform: translate3d(0, -12px, 0) scale(.75);
      }
    }
  }

  &:not(:placeholder-shown) {
    + {
      .volunteer-label {
        color: rgba(0, 0, 0, .5);
        transform: translate3d(0, -12px, 0) scale(.75);
      }
    }
  }

  &:focus {
    background: rgba(0, 0, 0, .05);
    outline: 0;
    box-shadow: inset 0 -2px 0 #07f;

    + {
      .volunteer-label {
        color: #07f;
        transform: translate3d(0, -12px, 0) scale(.75);

        + {
          .volunteer-focus-bg {
            transform: scaleX(1);
            transition: all 10ms ease;
          }
        }
      }
    }
  }
}

.volunteer-response-alarm {
  padding: 20px;
  color: #fff;
  opacity: 1;
  transition: opacity .6s;
  margin-bottom: 15px;
}

.volunteer-success {
  background-color: rgba(80, 192, 65, .99);
}

.volunteer-error {
  background-color: #db7093;
}

.volunteer-alarm {
  font-size: 12px;
  color: #db7093;
  margin-bottom: 10px;
}

.volunteer-form-horizontal {
  max-width: 400px;
  font-weight: 400;
  margin: 0 auto;
  padding-bottom: 50px;
  padding-top: 50px;
}

.volunteer-form-control {
  max-width: 400px;
  background-color: #fff;
  color: #000;
  letter-spacing: 1px;
  margin: 0;
}

.volunteer-send-button {
  margin-top: 15px;
  height: 34px;
  width: 400px;
  overflow: hidden;
  transition: all .2s ease-in-out;
}

.volunteer-alt-send-button {
  width: 400px;
  height: 34px;
  transition: all .2s ease-in-out;
  margin-top: 10px;

  &:hover {
    transform: translate3d(0, -29px, 0);
  }
}

.volunteer-send-text {
  display: block;
  margin-top: 13px;
  font: 700 12px Lato, sans-serif;
  letter-spacing: 2px;
}

.volunteer-learn-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  width: 25em;
  height: 3em;
  padding: 0 4em 0 1em;
  background: url(caret-down-solid.svg) no-repeat right .8em center/.8em, #fff;
  color: gray;
  border-radius: .25em;
  cursor: pointer;
  box-shadow: inset 0 -1px 0 #000;

  option {
    color: inherit;
    background-color: rgba(0, 0, 0, .3);
  }

  &:focus {
    outline: 0;
  }

  &::-ms-expand {
    display: none;
  }

  &:hover {
    background: url(caret-down-solid.svg) no-repeat right .8em center/.8em, rgba(0, 0, 0, .04);
  }
}

@media screen and (max-width: 900px) {
  .volunteer-form-horizontal {
    padding: 0 0 100px;
  }
  .volunteer-alt-send-button {
    margin-top: 3px;
  }
}

@media screen and (max-width: 569px) {
  .volunteer-form-control {
    margin: 0 auto;
  }
}

@media screen and (max-width: 420px) {
  .volunteer-send-button {
    width: 99%;
  }
}

