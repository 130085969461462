.ing-container {
  position: relative;
  background-color: #f5f5f5;
  padding-bottom: 5%;
}

.ing-title {
  top: 200px;
  left: 5%;
  position: absolute;
  text-align: right;
  background-color: rgb(47, 79, 79, 0.4);
  padding: 30px;
  border-radius: 10px;
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 5px;
  color: whitesmoke;
  transition: background-color 0.5s;

  &:hover {
    background-color: rgba(29, 79, 79, 0.9);
  }
}

.ing-text-area {
  font-size: 1.7em;
  line-height: 1.4;
  padding: 5% 20% 0 20%;
}

.ing-schedule-link {
  color: #1f98f4;
  text-decoration: none;
  font-weight: 700;
}

.ing-video {
  text-align: center;
  width: 100%;
  height: 1000px;
  padding: 10%;
}

.ing-imam-speech-container {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 100px auto 50px;
  padding: 20px;
  position: relative;
  width: 60%;
  box-shadow: 0 0 35px -16px #000;
  border-radius: 10px;
}

.ing-imam-image {
  margin: 0 30px 0 0;
  display: block;
  width: 100px;
  height: 100px;
}

.ing-imam-quote {
  margin-bottom: 20px;
  font-size: 20px;
  word-spacing: 2px;
}

@media screen and (max-width: 1300px) {
  .ing-text-area {
    font-size: 1.5em;
    padding: 5% 30%;
  }
  .ing-video {
    height: 700px;
  }
}

@media screen and (max-width: 900px) {
  .ing-title {
    top: 150px;
    text-align: center;
    font-weight: 100;
    font-size: 40px;
  }
}

@media screen and (max-width: 700px) {
  .ing-title {
    top: 100px;
    font-size: 30px;
  }
}

@media screen and (max-width: 569px) {
  .ing-video {
    height: 350px;
  }
  .ing-imam-speech-container {
    margin-top: 50px;
    width: 80%;
  }
  .ing-text-area {
    font-size: 20px;
    padding: 10% 9%;
  }
  .ing-imam-quote {
    font-size: 18px;
    word-spacing: 2px;
  }
  .ing-imam-image {
    margin: 0 15px 0 0;
    display: block;
    width: 60px;
    height: 60px;
  }
  .ing-title {
    left: 0;
  }
}
