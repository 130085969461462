.today_namaz-container {
  transition: all .3s ease-out;
  font-size: 2em;
  padding-bottom: 100px;
}

.mn_title {
  font-size: 40px;
}

.calendar {
  width: 100%;
  box-shadow: 0 0 35px -16px rgba(0, 0, 0, .75);
  font-family: Roboto, sans-serif;
  padding: 20px 30px 105px;
  color: #363b41;
  display: inline-block;
  background-color: #fff;
}

.calendar_header {
  border-bottom: 2px solid rgba(0, 0, 0, .08);
}
.header_copy_title_clarification{
  display: inline;
  font-size: 17px;
}

.header_copy {
  color: #a39d9e;
  font-size: 20px;
  padding-right: 100px;
}

.today_calendar {
  margin: 20px 0 40px;
}

.tc_form {
  width: 100%;
  height: 100px;
  background: darkseagreen;
  box-shadow: 0 0 52px -18px rgba(0, 0, 0, .75);
  border-radius: 5px;
  padding: 20px;
  color: #fff;
}

.tc_title {
  font-size: 20px;
  margin: 20px 0;
  display: inline-block;
}

.today_events {
  color: #a39d9e;
}
.today_events_clarification{
  padding-top: 40px;
  font-size: 13px;
  color: #a39d9e;
}

.te_item {
  margin: 18px 0;
  padding: 5px;
}

.te_item_active {
  background: darkseagreen;
  box-shadow: 0 0 52px -18px rgba(0, 0, 0, .75);
  border-radius: 5px;

  .ei_Dot {
    background-color: #fff;
  }
  .ei_Title {
    color: white;
  }
  .ei_Copy {
    color: white;
  }
}

.ei_Dot, .ei_Title {
  display: inline-block;
}

.ei_Dot {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: #a39d9e;
  box-shadow: 0 0 52px -18px rgba(0, 0, 0, .75);
}

.ei_Title {
  margin-left: 10px;
  color: #363b41;
}

.ei_Copy {
  font-size: 19px;
  margin-left: 27px;
}

.month_namaz_wrapper {
  margin-right: auto;
  margin-left: auto;
  width: 98%;
  display: block;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin-bottom: 20%;
}

.mn_table {
  width: 100%;
  border: 1px solid #eee;
}

.mn_header {
  display: flex;
  width: 100%;
  background: #d3d3d3;
  padding: 18px 0;
  position: sticky;
  top: 78px;
}

.mn_row {
  display: flex;
  width: 100%;
  padding: 18px 0;

  &:nth-of-type(odd) {
    background: #eee;
  }
}

.mn_data, .mn_event_item {
  flex: 1 1 20%;
  text-align: center;
  border-right: 1px solid #363b41;
  height: 100%;
}

.no-border {
  border-right: 0;
}


@media screen and (max-width: 500px) {
  .month_namaz_wrapper {
    width: 100%;
  }
}
