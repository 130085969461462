#contact {
  width: 100%;
  height: 100%;
}

.contact-type-writer {
  font-size: 50px;
  text-align: center;
  margin: 50px 10px 50px;
}

.contact-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto 50px;
  padding: 20px;
  position: relative;
  max-width: 1120px;
  box-shadow: 0 0 35px -16px rgb(0 0 0 / 75%);
}

.contact-input {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 280px;
  border-radius: 3px;
  overflow: hidden;

  .contact-label {
    position: absolute;
    top: -5px;
    left: 12px;
    font-size: 16px;
    color: rgba(0, 0, 0, .5);
    font-weight: 500;
    transform-origin: 0 0;
    transform: translate3d(0, 0, 0);
    transition: all .2s ease;
    pointer-events: none;
  }

  .contact-description-label {
    top: -147px;
  }

  .contact-focus-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: left;
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    border: 0;
    font-family: inherit;
    padding: 16px 12px 0 12px;
    height: 56px;
    font-size: 16px;
    font-weight: 400;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .3);
    color: #000;
    transition: all .15s ease;

    &:hover {
      background: rgba(0, 0, 0, .04);
      box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .5);
    }

    &:not(:-ms-input-placeholder) {
      + {
        .contact-label {
          color: rgba(0, 0, 0, .5);
          transform: translate3d(0, -12px, 0) scale(.75);
        }
      }
    }

    &:not(:placeholder-shown) {
      + {
        .contact-label {
          color: rgba(0, 0, 0, .5);
          transform: translate3d(0, -12px, 0) scale(.75);
        }
      }
    }

    &:focus {
      background: rgba(0, 0, 0, .05);
      outline: 0;
      box-shadow: inset 0 -2px 0 #07f;

      + {
        .contact-label {
          color: #07f;
          transform: translate3d(0, -12px, 0) scale(.75);

          + {
            .contact-focus-bg {
              transform: scaleX(1);
              transition: all 10ms ease;
            }
          }
        }
      }
    }
  }

  textarea {
    resize: none;
    border: none;
    outline: 0;
    height: 180px;
  }
}

.contact-textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  border: 0;
  font-family: inherit;
  padding: 25px 12px 0 12px;
  height: 56px;
  font-size: 16px;
  font-weight: 400;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .3);
  color: #000;
  transition: all .15s ease;
  max-width: 400px;
  background-color: #fff;
  letter-spacing: 1px;
  margin: 0;

  &:hover {
    background: rgba(0, 0, 0, .04);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .5);
  }

  &:not(:-ms-input-placeholder) {
    + {
      .contact-label {
        color: rgba(0, 0, 0, .5);
        transform: translate3d(0, -12px, 0) scale(.75);
      }
    }
  }

  &:not(:placeholder-shown) {
    + {
      .contact-label {
        color: rgba(0, 0, 0, .5);
        transform: translate3d(0, -12px, 0) scale(.75);
      }
    }
  }

  &:focus {
    background: rgba(0, 0, 0, .05);
    outline: 0;
    box-shadow: inset 0 -2px 0 #07f;

    + {
      .contact-label {
        color: #07f;
        transform: translate3d(0, -12px, 0) scale(.75);

        + {
          .contact-focus-bg {
            transform: scaleX(1);
            transition: all 10ms ease;
          }
        }
      }
    }
  }
}

.contact-response-alarm {
  padding: 20px;
  color: white;
  opacity: 1;
  transition: opacity 0.6s;
  margin-bottom: 15px;
}

.contact-success {
  background-color: rgba(80, 192, 65, 0.99);
}

.contact-error {
  background-color: #db7093;
}

.contact-alarm {
  font-size: 12px;
  color: #db7093;
  margin-bottom: 10px;
}

.contact-form-horizontal {
  max-width: 400px;
  font-weight: 400;
  margin-right: 100px;
}

.contact-form-control {
  max-width: 400px;
  background-color: #fff;
  color: #000;
  letter-spacing: 1px;
  margin: 0;
}

.contact-send-button {
  margin-top: 15px;
  height: 34px;
  width: 400px;
  overflow: hidden;
  transition: all .2s ease-in-out;
}

.contact-alt-send-button {
  width: 400px;
  height: 34px;
  transition: all .2s ease-in-out;
  margin-top: 10px;

  &:hover {
    transform: translate3d(0, -29px, 0);
  }
}

.contact-send-text {
  display: block;
  margin-top: 13px;
  font: 700 12px Lato, sans-serif;
  letter-spacing: 2px;
}

.contact-list {
  list-style-type: none;
  margin-left: 20px;
  padding-right: 20px;
}

.list-item {
  line-height: 4;
  color: #aaa;
}

.map-wrapper {
  padding: 20px;
  text-align: center;
}

.contact-text {
  font: 300 18px Montserrat;
  letter-spacing: 1px;
  color: #7a7979;
  line-height: 2;

  a {
    color: #7a7979;
    text-decoration: none;
    transition-duration: .2s;

    &:hover {
      color: #000;
      text-decoration: none;
    }
  }
}

.iconSize {
  font-size: 20px;
}

.contact-place {
  margin-left: 35px;
}

.rightSpace {
  margin-left: 10px;
}

.fa-copy {
  border-left: 10px;
}

.contact-phone {
  margin-left: 27px;
}

.contact-gmail {
  margin-left: 25px;
}

.mobileTitle {
  display: none;
}

@media screen and (max-width: 900px) {
  .contact-type-writer {
    font-size: 30px;
    padding-left: 10% ;
    padding-right: 10%;
    height: 60px;
  }

  .mobileTitle {
    display: block;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 50px;
    font-size: 30px;
  }
  .contact-wrapper {
    display: flex;
    flex-direction: column;
  }
  .direct-contact-container {
    margin: 60px auto 100px;
    max-width: 330px;
  }
  .contact-form-horizontal {
    margin: 0 auto 100px;
  }
  .contact-text {
    font: 300 20px Montserrat;
  }
  .contact-list {
    padding-right: 0;
  }
  .fa {
    line-height: 2;
  }
  .contact-alt-send-button {
    margin-top: 3px;
  }
  .send-text {
    margin-top: 8px;
  }
}

@media screen and (max-width: 569px) {
  .direct-contact-container {
    float: none;
    margin: 60px auto 0;
  }
  .form-wrapper {
    float: none;
    margin: 0 auto;
  }
  .contact-form-control {
    margin: 0 auto;
  }
  .contact-textarea {
    margin: 0 auto;
  }
  .contact-email {
    width: 280px;
  }
  .contact-name {
    width: 280px;
  }
}

@media screen and (max-width: 420px) {
  .contact-send-button {
    width: 99%;
  }
}
