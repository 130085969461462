.about-us-container {
  position: relative;
  background-color: #fff;
}

.about-us-title {
  text-align: center;
  min-height: 750px;
  background-color: #f8f9fa;
  padding: 15%;
}

.about-us-title-main {
  font-weight: 1000;
  font-size: 75px;
  line-height: 1.5;
}

.about-us-subtitle {
  font-weight: 500;
  font-size: 30px;
}

.about-us-background-blue {
  background-color: #1f98f4;
}

.about-us-play-icon {
  margin-top: 3%;
  font-size: 60px;
  color: darkslategray;

  &:hover {
    color: darkgray;
    transition: 0.5s;
  }
}

.about-us-button {
  background-color: transparent;
  margin-top: 30px;
}

.about-us-icon-description {
  text-space: 1px;
  line-height: 25px;
  color: darkslategray;
  font-weight: bolder;

  &:hover {
    color: #7a7979;
    transition: all 0.3s ease-out;
  }
}

.about-us-video-player {
  width: 90%;
  position: absolute;
  left: 50%;
  top: 17%;
  display: none;
  transform: translate(-50%, -50%);
}

.about-us-section-container {
  position: relative;
  padding: 100px 15px;
  margin-right: auto;
  margin-left: auto;
}

.about-us-body-title {
  word-spacing: 3px;
  font-size: 30px;
  font-weight: bolder;
  margin-bottom: 25px;
  color: darkslategray;
}

.about-us-body-text {
  line-height: 30px;
  font-size: 20px;
  word-spacing: 4px;
}

.about-us-strong-color {
  color: cornflowerblue;
  font-weight:bold;
}

.about-us-section {
  margin-left: 5%;
  margin-right: 5%;
}

.about-us-text-column {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.about-us-icon-column {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.about-us-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 54px;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.about-us-flex-with-no-decoration {
  display: -ms-flexbox !important;
  display: flex !important;
  text-decoration: none;
}

.about-us-align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
  margin-bottom: 1.5rem !important;
}

.about-us-wrap-icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50px;
  flex: 0 0 50px;
  text-align: center;
  width: 50px;
  height: 50px;
  margin-right: 20px;
  position: relative;
  border-radius: 30%;
  -webkit-box-shadow: 0 15px 30px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 5%);
  color: darkseagreen;
  background-color: transparent;
}

.about-us-fas-icon {
  font-size: 23px;
  margin-top: 13px;
}

.fa-play-circle {
  margin-left: 4px;
}

.about-us-image {
  margin-top: 100px;
  width: 90%;
  height: auto;
}

.about-us-video {
  margin-top: 50px;
  width: 100%;
  height: 500px;
}

@media screen and (min-width: 992px) {
  .about-us-icon-column {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .about-us-text-column {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    margin-right: auto;
  }
}

@media screen and (min-width: 1200px) {
  .about-us-section-container {
    max-width: 1140px;
  }
}

@media screen and (max-width: 1000px) {
  .about-us-title-main {
    font-size: 50px;
  }
  .about-us-subtitle {
    font-size: 20px;
  }
  .about-us-video-player {
    top: 13%;
  }
  //.about-us-image {
  //  margin-top: 100px;
  //  width: 100%;
  //  height: auto;
  //}
}

@media screen and (max-width: 580px) {
  .about-us-title {
    padding-top: 50%;
    min-height: 820px;
  }
  .about-us-title-main {
    font-size: 50px;
  }
  .about-us-video-player {
    top: 11%;
  }
  .about-us-subtitle {
    font-size: 20px;
  }
  .about-us-youtube-video {
    height: 300px;
  }
  .about-us-icon-column {
    margin-left: 0;
  }
  .about-us-image {
    /*      margin-left: 18%;
          margin-top: 100px;*/
  }
  .about-us-video {
    margin-top: 100px;
    width: 100%;
    height: 700px;
  }
}
