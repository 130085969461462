* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}

html, body {
    /*min-height: 100%;*/
    height: auto;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    position: relative;
    /*padding-bottom: 100%;*/
    height: 100%;
}

.black {
    color: black;
}

.darkslategray {
    color: darkslategray;
}

button {
    border: white;
}

@media screen and (max-width: 420px) {
    html, body {
        margin-right: -55px
    }
}
