.footer-container {
  position: relative;
  background-color: #2f4f4f;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  content: '';
  left: 0;
  bottom: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

/*.footer-subscription-heading { can be applied to footer
  margin-bottom: 24px;
  font-size: 24px;
}*/

.footer-payment-link {
  width: auto;
  height: 80px;
  display: flex;
  align-items: center;
}
.footer-payment-icons {
  height: 40%;
}
.footer-payment-icon-sbp {
  height: 100%;
}

.footer-subscription-text {
  margin-bottom: 24px;
  line-height: 1.5em;
  font-size: 20px;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;

  h2 {
    margin-bottom: 16px;
    margin-left: -20px;
    padding: .5rem 1rem;
    font-size: 1.5rem;
  }

  > h2 {
    color: #fff;
  }

  a {
    color: #fff;
    text-decoration: none;
    margin-bottom: .5rem;

    &:hover {
      color: #d3d3d3;
      transition: .1s ease-out;
    }
  }
}

.footer-size {
  font-size: 1rem;
}

.footer-social-icon-link {
  color: #b0c4de;
  font-size: 24px;
}

.footer-social-media {
  max-width: 1000px;
  width: 100%;
}

.footer-social-media-wrap {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.footer-social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
}

.footer-website-rights {
  text-decoration: none;
  color: #d3d3d3;
  margin-top: 45px;
  margin-bottom: 16px;
  font-size: 10px;
}

.footer-line {
  width: 80%;
  border: 1px solid gray;
}

@media screen and (min-width: 1300px) {
  .footer-subscription-text {
    margin-left: 20%;
    margin-right: 21%;
    text-align: center;
  }
}

@media screen and (max-width: 1300px) {
  .footer-subscription-text {
    margin-left: 10%;
    margin-right: 11%;
    text-align: center;
  }
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }
  .footer-link-wrapper {
    flex-direction: column;
    margin-right: -30px;
  }
  .footer-social-media-wrap {
    flex-direction: column;
  }
  .footer-subscription-text {
    margin-left: 7%;
    margin-right: 8%;
    text-align: center;
  }
}
