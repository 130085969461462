.navbar {
  background: #2f4f4f;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar.active {
  background: #fff;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  margin-top: 10px;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 80vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: .5rem 1rem;
  height: 100%;

  &:hover {
    border-bottom: 4px solid gray;
    transition: all .1s ease-out;
  }
}

.nav-links.active {
  color: #2f4f4f;
}

.fa-bars {
  color: #000;
}

.white {
  color: #fff;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 130vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all .5s ease;
    justify-content: start;
  }
  .nav-menu.active {
    background: #242222;
    left: 0;
    transition: all .5s ease;
    z-index: 1;
  }
  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;

    &:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  }
  .nav-links.active {
    color: white;

    &:hover {
      color: #242424;
    }
  }
  .navbar-logo {
    position: absolute;
    top: -33px;
    left: 0;
    transform: translate(25%, 55%);
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
  .fa-times {
    font-size: 2rem;
  }
}
