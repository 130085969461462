.dropdown-menu {
  width: 200px;
  position: absolute;
  top: 80px;
  list-style: none;
  text-align: start;

  li {
    background: #4b4f5e;
    cursor: pointer;

    &:hover {
      background: silver;
    }
  }
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
}

@media screen and (max-width: 960px) {
  .dropdown-menu {
    position: sticky;
    display: block;
    width: 100%;
    text-align: center;
  }
}
