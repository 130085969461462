.news-list-item {
  padding: 20px 0 25px;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;

  &:hover {
    background-color: #f5f5f5;
    transition: all .3s ease-out;
  }
}

.news-list-item-wrap {
  min-height: 117px;
  position: relative;
  max-width: 1000px;
  margin: 0 10%;
}

.news-list-item-bottom {
  position: absolute;
  bottom: -5px;
  left: 25px;
}

.news-list-item-link {
  display: block;
  text-decoration: none;
}

.news-list-item-title-wrap {
  float: left;
}

.news-list-item-title {
  font: normal 24px/28px GraphikCy-Semibold, 'Helvetica CY', Arial, sans-serif;
  display: block;
  padding-bottom: 24px;
  margin-right: 205px;
  color: #222;
  padding-top: 1%;
}

.news-list-item-category {
  font-size: 14px;
  color: #999;
}

.news-list-item-image {
  display: block;
  max-width: 100% !important;
  height: auto !important;
}

.news-list-item-image-block {
  float: right;
  position: relative;
  width: 180px;
  margin-top: 5px;
  margin-left: -180px;
}

@media (max-width: 1600px) {
  .news-list-item-title {
    font: normal 20px/28px GraphikCy-Semibold, 'Helvetica CY', Arial, sans-serif;
  }
}

@media (max-width: 850px) {
    .item_image-mob {
      .news-list-item-title {
        padding-bottom: 15px;
      }

      .news-list-item-title-wrap {
        padding-bottom: 15px;
      }

      .news-list-item-image-block {
        float: none;
        display: block !important;
        width: 100%;
        margin-bottom: 5px;
        margin-left: 0;
      }
    }
    .news-list-item {
      padding: 22px 0 20px;
    }
    .news-list-item-wrap {
      min-height: 0;
    }
    .news-list-item-bottom {
      position: static;
      padding-bottom: 7px;
    }
    .news-list-item-title-wrap {
      float: none;
      display: block;
      font: normal 22px/24px GraphikCy-Semibold, 'Helvetica CY', Arial, sans-serif;
      padding-bottom: 0;
      margin-right: 0;
    }
    .news-list-item-image-block {
      display: none !important;
    }
    .news-list-item-title {
      font: normal 22px/24px GraphikCy-Semibold, 'Helvetica CY', Arial, sans-serif;
      padding-bottom: 0;
      margin-right: 0;
      font-size: 27px;
      font-weight: 700;
    }
    .news-list-item-category {
      font-size: 18px;
    }
  }

  @media (max-width: 640px) {
    .news-list-item-wrap {
      min-height: 0;
      max-width: 540px;
      margin: 0 5%;
    }
  }
