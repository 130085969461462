/*.l-col-center {
    max-width: 680px;
    margin: 0 auto;
}*/
.news-list-container {
  margin: 3% 20%;
  border-top: 1px solid #ddd;
  max-width: 1300px;
}

.card-title {
  padding-top: 5%;
  text-align: center;
}

.center {
  text-align: center;
}

.news-list-button {
  cursor: pointer;
  margin: 5% 5% 0;
  padding: 30px;
  background-color: transparent;
}

.news-list-category-title {
  padding: 50px 25px 17px;
  font-weight: bolder;
  font-size: 44px;
  line-height: 46px;
  letter-spacing: -.5px;
  max-width: 680px;
  margin: 0 auto;
}

@media (min-width: 1260px) {
  .news-list-title {
    display: block;
  }
}

@media (max-width: 1300px) {
  .news-list-container {
    margin: 7% 10%;
  }
}

@media (max-width: 850px) {
  .news-list-container {
    border-top: 0;
    margin: 0;
  }
  .news-list-category-title {
    padding-bottom: 50px;
  }
}

@media (max-width: 640px) {
  .news-list-button {
    margin: 5%;
  }
}
