
.donation-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
}

.donation-form-wrapper {
  margin: 0 auto;
  max-width: 550px;
  width: 100%;
  background: white;
}

.donation-steps {
  padding-bottom: 18px;
  margin-bottom: 35px;
  border-bottom: 1px solid #DDE3EC;
}

.donation-steps ul {
  padding: 0;
  margin: 0;
  list-style: none;
  //display: flex;
  gap: 40px;
}

.donation-steps li {
  display: flex;
  align-items: center;
  gap: 14px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #536387;
}

.donation-steps li span {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #DDE3EC;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #536387;
}

.donation-steps li.active {
  color: #07074D;;
}

.donation-steps li.active span {
  background: #6A64F1;
  color: #FFFFFF;
}

.donation-input-flex {
  display: flex;
  gap: 20px;
  margin-bottom: 22px;
}

.donation-input-flex > div {
  width: 50%;
}

.donation-form-input {
  width: 100%;
  padding: 13px 22px;
  border-radius: 5px;
  border: 1px solid #DDE3EC;
  background: #FFFFFF;
  font-weight: 500;
  font-size: 16px;
  color: #536387;
  outline: none;
  resize: none;
}

.donation-form-input:focus {
  border-color: #6a64f1;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.donation-form-label {
  color: #07074D;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: block;
  margin-bottom: 10px;
}



.donation-form-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
  margin-top: 25px;
}


.donation-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  border-radius: 5px;
  padding: 10px 25px;
  border: none;
  font-weight: 500;
  background-color: #6A64F1;
  color: white;
  cursor: pointer;
}

.donation-btn:hover {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
  background-color: #6bf164;
}


.donation-checkbox {
  /*display: block;*/
  height: 25px;
  width: 25px;
  background-color: #252e48;
  color: palegreen;
  padding: 0 10px;
  margin: 10px;
}

.donation-checkbox-text {
  padding: 3px 5px;
  letter-spacing: 2px;
}

.donation-link {
  color: #1f98f4;
  text-decoration: none;
  font-weight: 700;
}
