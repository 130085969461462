.signInContainer {
    min-height: 692px;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
    overflow: hidden;
    background: linear-gradient(
            108deg,
            rgba(1, 147, 86, 1) 0%,
            rgba(10, 201, 122, 1) 100%
    );
}

.form-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width: 400px) {
    .form-wrap {
        height: 80%;
    }

    .form {
        padding: 32px 32px;
    }
}

.icon {
    margin-left: 32px;
    margin-top: 32px;
    text-decoration: none;
    color: white;
    font-weight: 700;
    font-size: 32px;
}

@media screen and (max-width: 480px) {
    .icon {
        margin-left: 16px;
        margin-top: 8px;
    }

    .form-content {
        padding: 10px;
    }

}

.form-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form {
    background: darkslategray;
    max-width: 400px;
    height: auto;
    width: 100%;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    padding: 90px 32px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);
}

.formH1 {
    margin-bottom: 40px;
    color: white;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
}

.form-label {
    margin-bottom: 8px;
    font-size: 18px;
    color: white;
}

.form-input {
    padding: 16px 23%;
    margin-bottom: 32px;
    border: none;
    border-radius: 10px;
}

.form-button {
    background: #01bf71;
    padding: 16px 40%;
    border: none;
    border-radius: 7px;
    color: white;
    font-size: 20px;
    cursor: pointer;
    text-align: center;
}

.text {
    text-align: center;
    margin-top: 24px;
    color: white;
    font-size: 14px;
}
