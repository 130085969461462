.hero-container {
  background-color: transparent;
  height: 95vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  object-fit: contain;
  justify-content: center;
  font-size: 3rem;
}

.news-list-scroll-point {
  margin-bottom: 79px;
}

.hero-btn-height {
  margin-bottom: 166px;
}

@media screen and (max-width: 768px) {
  .hero-container {
    height: 119vh;
  }
}
