.application_container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
  line-height: 1.25;
}

.dropdown {
  position: center;
  font-size: 20px;
  border-radius: 5px;
  border: 1px solid lightgray;
  text-align: center;
  padding: 10px;
}

.dropdown-wrapper {
  padding: 15px;
}

.load-more-button {
  margin: 30px;
}

.application_table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;

  caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
  }

  tr {
    border: 1px solid #ddd;
    padding: .35em;
  }

  th, td {
    border-right: 1px solid #ddd;
    border-bottom: 1px solid black;
    padding: .625em;
    text-align: center;
    word-wrap: break-word;
  }

  th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
  }
}

.at_body {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

@media screen and (max-width: 600px) {
  .application_table {
    border: 0;

    caption {
      font-size: 1.3em;
    }

    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tr {
      border-bottom: 2px solid #ddd;
      display: block;
      margin-bottom: 1.625em;
    }

    td {
      border-bottom: 1px solid #ddd;
      border-right: 0;
      display: block;
      font-size: .8em;
      text-align: right;
      border-left: 10px;

      &::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
