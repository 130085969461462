.news-container {
  width: 100%;
  max-width: 684px;
  margin: 0 auto;
  text-align: center;
}

.news-title {
  text-align: left;
  letter-spacing: -.011em;
}

.news-flex-div {
  display: flex;
  align-items: center;
}

.bordRadius {
  border-radius: 50%;
  text-align: right;
}

.news-creator-name {
  color: gray;
  margin-left: 12px;
  line-height: 30px;
  font-size: 12px;
  letter-spacing: -.07em;
}

.news-heading-date-created {
  color: rgba(117, 117, 117, 1);
  margin-left: 7px;
  word-spacing: -5px;
  line-height: 20px;
  font-size: 14px;
}

.news-main-image {
  width: 100%;
  height: 100%;
  max-width: 100%;
  border-radius: 5px;
}

.news-date-created {
  padding-left: 10px;
}

.mg-top {
  margin-top: 33px;
}

.news-description {
  margin: 2em 0;
  text-align: left;
  letter-spacing: -.003em;
  font-size: 21px;
  white-space: pre-wrap;
}

.news-author-wrapper {
  display: flex;
  text-align: left;
  margin-left: 4px;
  margin-bottom: 30px;
}

.news-author {
  text-align: left;
  font-size: 13px;
  margin-bottom: 7px;
  font-weight: 700;
  letter-spacing: -.01em;
}

@media screen and (max-width: 960px) {
  .news-container {
    max-width: 93%;
    margin: 0 auto;
  }
}
