/*size*/
.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 6px;
}

.btn-primary {
  background-color: var(--primary);
  color: white;
  border: 1px var(--primary);
}

.btn-outline {
  background-color: transparent;
  color: darkslategray;
  padding: 8px 20px;
  border: 1px var(--primary);
  transition: all 0.3s ease-out;
}

.btn-medium {
  padding: 8px 20px;
  font-size: 20px;

  &:hover {
    background: darkslategray;
    color: white;
    transition: all 0.3s ease-out;
  }
}

.btn-large {
  padding: 12px 26px;
  font-size: 20px;

  &:hover {
    background: darkslategray;
    color: white;
    transition: all 0.3s ease-out;
  }
}
